.profileNotFound {
    font-family: $heading-font;

    p {
        line-height: 1.5;
        font-weight: bold;
        font-size: 1.06em;
        margin-bottom: 1rem;

        i {
            @include size(1.06em); } } }
