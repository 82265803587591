.night-city-nav {
    padding: .5em 0;
    background-color: lighten(gray, 47%);

    @include iphoneSE {
        padding: .75em 0; }

    @include tablet {
        padding: 1em 0; }

    @include desktop-up {
        padding: 2em 0;
        flex-basis: 10%;
        background: none; } }


.night-city-nav__list {
    display: flex;
    justify-content: space-between;
    padding: 0 2em;
    font-family: $text-font;

    @include iphoneSE {
        padding: 0 1em; }

    @include desktop-up {
        padding: 0;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between; } }

.night-city-nav__link {

    font-size: 14px;

    i {
        @include size(15px); }

    a {
        padding: 0 8px;
        color: $black;
        font-weight: bold; }

    &:last-child a {
        padding-right: 0; }

    @include iphoneSE {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            margin-bottom: 3px; }

        a {
            padding: 0; } }

    @include tablet-up {
        font-size: 1em;
        text-decoration: underline;

        i {
            @include size(1em); } }

    @include desktop-up {
        margin-bottom: 1.5em;
        display: flex;
        align-items: center;
        width: 70px;
        text-decoration: none;
        cursor: pointer;

        a {
            padding: 0;
            margin-left: 8px;
            position: relative;
            transition: color 200ms ease-out;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background-color: $pink;
                transform-origin: left;
                transform: scaleX(0);
                transition: transform 200ms ease-out; } }

        i {
            transition: transform 200ms ease-out; }

        &:last-child {
            margin-bottom: 0; }

        &:hover {
            i {
                transform: scale(1.25); }

            a {
                color: $pink;

                &::before {
                   transform: scaleX(1); } } } } }
