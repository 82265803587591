@mixin iphoneSE {
    @media screen and (device-aspect-ratio: 40/71) {
        @content; } }

@mixin phone-up {
    @media (max-width: 599px) {
        @content; } }

@mixin tablet {
    @media (min-width: 600px) and (max-width: 1199px) {
       @content; } }

@mixin tablet-up {
    @media (min-width: 600px) {
       @content; } }

@mixin desktop-up {
    @media (min-width: 1200px) {
       @content; } }

@mixin retina {
    @media (min-width: 1800px) {
       @content; } }
