.paginator {
    ul {
        padding: 1em 0;
        display: flex;

        @include desktop-up {
            padding: 1em 0 2em; } } }

.paginator__link {
    font-family: $text-font;
    font-size: 14px;
    font-weight: bold;
    margin-right: .8em;
    text-decoration: underline;
    background-color: #f0f0f0;
    padding: 1em 1.5em;
    border-radius: 5px;

    i {
        @include size(14px);
        transition: transform 170ms ease-out; }

    a {
        color: $black; } }


.link__prev {

    a {
        margin-left: 5px; }

    &:hover i, a {
        transform: translateX(-4px); } }

.link__more {

    a {
        margin-right: 5px; }

    &:hover i {
        transform: translateX(4px); } }
