.notFound__content {
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    line-height: 1.5;

    p {
        margin-bottom: 1em;
        font-size: 1.06em;

        &:last-child {
            margin-bottom: 0; }

        i {
            @include size(1.06em); } } }
