.newsItem {
    margin-bottom: 1.5em;
    font-family: $text-font;

    @include desktop-up {
        margin-bottom: 2em; } }

.newsItem__header {

    h2 {
        display: inline;
        font-size: 1.06em;
        line-height: 1.33;
        font-weight: bold;
        font-family: $heading-font;

        @include tablet-up {
            font-size: 1.09em; }

        @include desktop-up {
            line-height: 1.44;
            font-size: 1.19em; }

        &.story-not-found {
            color: $gray;
            font-style: italic;

            i {
                @include size(1.06em);

                @include tablet-up {
                    @include size(1.09em); } } }

        a {
            color: inherit;

            @include desktop-up {

                &:hover {
                    text-decoration: underline; } } } } }


.newsItem__info {
    margin-top: .8em;
    display: flex;
    font-size: 13px;
    color: $gray;
    flex-wrap: wrap;

    @include tablet {
        font-size: 14px; }

    @include desktop-up {
        font-size: 15px;
        margin-top: .4em; }

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 1em;

        @include desktop-up {
            margin-right: 1.4em; }

        &.newsItem__story-url {
            display: none;

            @include desktop-up {
                display: inline-block; } }

        @include iphoneSE {
            line-height: 1.6; }

        i {
            @include size(14px);

            @include desktop-up {
                @include size(16px); } }

        span {
            padding-left: 8px;

            @include desktop-up {
                padding-left: 6px; } }

        a {
            color: inherit;
            text-decoration: underline; } } }
