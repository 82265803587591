// font imports
@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700|PT+Sans+Caption:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap');


// declarations
$logo-font: 'Noto Sans JP', sans-serif;
$heading-font: 'Inconsolata', monospace;
$text-font: 'PT Sans Caption', sans-serif;

// Colors
$black: #141414;
$white: #FFFFFF;
$gray: lighten(gray, 8%);
$pink: #ed3993;
$red: #ed1515;
$turquoise: #0af28d;
