.askhn {
    @include iphoneSE {
        padding: 1em  !important; } }

.askhn__content {

    @include desktop-up {
        max-width: 46em;
        margin: 0 auto; }

    h2 {
        font-family: $heading-font;
        font-size: 1.06em;
        line-height: 1.42;
        font-weight: bold;

        @include desktop-up {
            font-size: 1.3em;
            line-height: 1; } } }

.askhn__meta {
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet-up {
        justify-content: flex-start; } }


.askhn__meta li {
    font-size: 13px;
    display: flex;
    align-items: center;
    color: $gray;

    @include tablet-up {
        margin-right: 1em; }

    @include desktop-up {
        font-size: 14px; }

    i {
        @include size(13px);

        @include desktop-up {
            @include size(14px); } }

    span {
        padding-left: 4px; }

    a {
        color: inherit;
        text-decoration: underline; } }

.askhn__body {

    font-size: 14px;
    font-family: $text-font;
    line-height: 1.22;

    @include desktop-up {
        line-height: 1.6; }

    p {
        margin-bottom: 1em;

        &:first-child {
            margin-top: 1em; } } }

.askhn__link {
    display: block;
    font-family: $heading-font;
    color: $black;
    font-weight: bold;
    font-size: 1.06em;
    text-decoration: underline;
    margin-top: 1.5em;

    span {
        padding-left: 4px; }

    i {
        @include size(1.06em); }

    @include tablet-up {
        margin-top: 1em; }

    @include desktop-up {
        margin-top: 2.5em; } }
