.loader {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 1.5em;
        font-family: $heading-font;
        font-weight: bold;

        i {
            margin-left: 1em;
            @include size(1.5em);
            animation: zoom 2s ease infinite; } } }


@keyframes zoom {
    0% {
       transform: scale(1); }

    50% {
       transform: scale(2); }

    100% {
       transform: scale(1); } }

