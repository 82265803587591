@import 'utils/_index';
@import 'components/_index';

main.container {
    padding: 2em;

    @include desktop-up {
        flex: 1; } }

.page-content {
    @include desktop-up {
        max-width: 75%;
        margin: 0 auto; } }

.hn-container {
    @include desktop-up {
        height: 100vh; // hack to avoid weird stretching effect when switching pages
        padding: 1em 0;
        display: flex;
        max-width: 63em;
        margin: 0 auto;
        position: relative; } }
