header.logo-header {
    padding: 1em 2em;
    border-bottom: 1px solid #000;
    background-color: $black;

    @include iphoneSE {
        padding: 1em; }

    @include tablet {
        padding: 1.5em 2em; }

    @include desktop-up {
        padding: 1em 0; }

    h1 {
        font-size: 1.3em;
        font-family: $logo-font;
        color: $white;
        font-weight: 700;
        display: inline-block;

        a {
            color: inherit; }

        @include tablet {
            font-size: 2em; }

        @include desktop-up {
            font-size: 2.2em; } } }

.logo-container {

    max-width: 56em;
    margin: 0 auto;
    text-align: center; }
