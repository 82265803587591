.userProfile {
    max-width: 300px;
    margin: 0 auto;

    @include iphoneSE {
        padding: 1em; }

    @include desktop-up {
        max-width: 100%; } }


.userProfile__heading {
    @include page-heading($pink); }


.userProfile__info {
    margin-top: 2em;
    font-family: $text-font;

    @include iphoneSE {
        margin-top: 1.5em; }

    li {
        font-size: 1.06em;
        margin-bottom: .8em;
        display: flex;
        align-items: center;

        @include iphoneSE {
            font-size: .9em; }

        i {
            @include size(1.06em);

            @include iphoneSE {

                @include size(.9em); } }

        span {
            display: inline-block;
            padding-left: 8px;
            color: $gray;

            strong {
                font-weight: bold;
                color: $black; } } } }

.userProfile__bio {
    font-size: 14px;
    overflow-wrap: break-word;
    color: $gray;
    line-height: 1.3;
    padding-left: 1em;

    @include iphoneSE {
        font-size: .8em; } }
