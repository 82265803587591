main.newsList {
    padding: 1em;

    @include iphoneSE {
        padding: 1em; }

    @include tablet {
        padding: 1.5em 2em; }

    @include desktop-up {
        padding: 2em;
        flex: 1; } }
