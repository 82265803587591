.error__heading {
    @include page-heading($red); }

.error p {
    font-size: 1.06em;
    font-family: $heading-font;
    font-weight: bold;
    margin-bottom: 1em;
    line-height: 1.4;

    a {
        color: $red;
        text-decoration: underline; }

    i {
        @include size(1.06em);
        margin-left: 4px;
        margin-top: -4px; }

    @include desktop-up {
        font-size: 1.1em;
        line-height: 1.5; } }
